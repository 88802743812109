import "./App.css"
import "./css/demo.css"
import "./css/component.css"
import { Uploader } from "./upload"
import { useEffect, useState, useRef, useCallback } from "react"


function App({is_mirax}) {
    const [uploadInfos, setUploadInfos] = useState([])
    const [uploaders, setUploaders] = useState([])
    const fileInput = useRef(null)
    const folderInput = useRef(null)

    const messageParent = (msg, extra=undefined) => {window.parent.postMessage({msg: msg, frame: window.name, extra: extra}, "*");}

    useEffect(() => {
        if (uploadInfos && uploadInfos.length > 0) {
            let percentage = undefined
            let uploader = null
            let uploader_array_files = []
            for(let i=0; i<uploadInfos.length; i++) {
                percentage = undefined
                uploader = new Uploader({
                    fileName: uploadInfos[i].uploadKey,
                    companyName: uploadInfos[i].companyName,
                    file: uploadInfos[i].file,
                    allSize: uploadInfos[i].allSize,
                    currentCount: uploadInfos[i].currentCount,
                    fileId: uploadInfos[i].fileId
                });
                uploader_array_files.push(uploader);

                uploader
                    .onProgress(({percentage: newPercentage}) => {
                        // to avoid the same percentage to be logged twice
                        percentage = newPercentage;
                        messageParent("percentage", {
                            percentage: percentage,
                            fileName: uploadInfos[i].fileName,
                            fileId: uploadInfos[i].fileId
                        })
                    })
                    .onError((error) => {
                        setUploadInfos(undefined);
                        if (error.message === "Upload canceled by user") {
                            console.log(error);
                        } else {
                            console.error(error);
                        }
                        messageParent("error");
                    })
                    .onSuccess(() => {
                        console.log(`finished ${uploadInfos[i].uploadKey}`);
                        messageParent("success", {allSize: uploadInfos[i].allSize});
                    });

                uploader.start();
            }
            setUploaders(uploader_array_files);
        }
    }, [uploadInfos]);

    const onCancel = useCallback(() => {
        if (uploaders) {
            for(const uploader of uploaders) {
                uploader.abort();
            }
            setUploadInfos(undefined);
        }
    }, [uploaders]);

    const setNoJs = () => {
        var r=document.querySelectorAll("html")[0];
        r.className=r.className.replace(/(^|\s)no-js(\s|$)/,"$1js$2");

        var inputs = document.querySelectorAll( '.inputfile' );
        Array.prototype.forEach.call( inputs, function( input )
        {
            var label	 = input.nextElementSibling,
                labelVal = label.innerHTML;

            input.addEventListener( 'change', function( e )
            {
                var fileName = '';
                if( this.files && this.files.length > 1 )
                    fileName = ( this.getAttribute( 'data-multiple-caption' ) || '' ).replace( '{count}', this.files.length );
                else
                    fileName = e.target.value.split( '\\' ).pop();

                if( fileName )
                    label.querySelector( 'span' ).innerHTML = fileName;
                else
                    label.innerHTML = labelVal;
            });

            // Firefox bug fix
            input.addEventListener( 'focus', function(){ input.classList.add( 'has-focus' ); });
            input.addEventListener( 'blur', function(){ input.classList.remove( 'has-focus' ); });
        });

        var inputFolder = document.querySelectorAll( '.inputfolder' );
        Array.prototype.forEach.call( inputFolder, function( input )
        {
            var label	 = input.nextElementSibling,
                labelVal = label.innerHTML;

            input.addEventListener( 'change', function( e )
            {
                var fileName = '';
                if( folderInput.current.files.length > 1 )
                    fileName = ( this.getAttribute( 'data-multiple-caption' ) || '' ).replace( '{count}', folderInput.current.files.length );

                if( fileName )
                    label.querySelector( 'span' ).innerHTML = fileName;
                else
                    label.innerHTML = labelVal;
            });

            // Firefox bug fix
            input.addEventListener( 'focus', function(){ input.classList.add( 'has-focus' ); });
            input.addEventListener( 'blur', function(){ input.classList.remove( 'has-focus' ); });
        });
    }

    useEffect(() => {
        setNoJs();
    },[]);

    // Setup message events and send "ready" message to the parent
    useEffect(() => {
        window.onmessage = function(e) {
            const msg = e.data.msg;
            if (msg === 'hello') {
                console.log('Communication successful')
            }
            else if(msg === "start") {
                const extra = e.data.extra;
                const companyName = extra.companyName;
                if (companyName === undefined){
                    console.error("Invalid start message from parent page")
                    messageParent("error")
                    return
                }
                let array_files = [];
                if(folderInput.current.files.length > 0){
                    let ini_file;
                    let index_file;

                    for(let i=0; i<folderInput.current.files.length; i++) {
                        let file = folderInput.current.files[i];
                        if(file.name === "Slidedat.ini"){
                            console.log("Slidedat.ini found");
                            ini_file = file;
                        } else if(file.name === "Index.dat"){
                            console.log("Index.dat");
                            index_file = file;
                        }
                    }

                    if(!ini_file){
                        console.log("No Slidedat.ini file");
                        messageParent("error");
                        return;
                    }
                    if(!index_file){
                        console.log("No Index.dat file");
                        messageParent("error");
                        return;
                    }

                    const file_name = fileInput.current.files[0].name;
                    const folder_name = file_name.replace('.mrxs', '');
                    let fileCount = 1;

                    array_files.push({
                        uploadKey: fileInput.current.files[0].name,
                        companyName: companyName,
                        file: fileInput.current.files[0],
                        allSize: fileInput.current.files.length + folderInput.current.files.length,
                        currentCount: fileCount,
                        fileId: fileCount - 1
                    });

                    for(let i=0; i<folderInput.current.files.length; i++) {
                        fileCount = fileCount + 1;
                        array_files.push({
                            uploadKey: folder_name + "/" + folderInput.current.files[i].name,
                            companyName: companyName,
                            file: folderInput.current.files[i],
                            allSize: fileInput.current.files.length + folderInput.current.files.length,
                            currentCount: fileCount,
                            fileId: fileCount - 1
                        });
                    }
                } else {
                    for(let i=0; i < fileInput.current.files.length; i++) {
                        array_files.push({
                            uploadKey: fileInput.current.files[i].name,
                            companyName: companyName,
                            file: fileInput.current.files[i],
                            allSize: fileInput.current.files.length,
                            currentCount: i + 1,
                            fileId: i
                        });
                    }
                }
                setUploadInfos(array_files);
            }
            else if(msg === "cancel") {
                console.log("Canceling upload...");
                onCancel();
            }
        }
        messageParent("ready");
        return () => {
            window.onmessage = undefined;
        }
    }, [onCancel]);

    const onFileChanged = () => {
        var parts = fileInput.current.files[0].name.split('.');
        if (parts[parts.length - 1].toLowerCase() === 'mrxs') {
            document.getElementById("type_folder").removeAttribute("disabled");
            document.getElementById("label_type_folder").removeAttribute("class");
        }
        messageParent("file-set", {
            fileInput: fileInput.current.files,
            isFolder: '0'
        });
    }

    const onFolderChanged = () => {
        if(fileInput.current.files[0] !== undefined && folderInput.current.files[0] !== undefined) {
            messageParent("file-set", {
                fileInput: fileInput.current.files,
                fileName: fileInput.current.files[0].name.replace('.mrxs', ''),
                folderInput: folderInput.current.files,
                isFolder: '1'
            });
        } else if(fileInput.current.files[0] === undefined) {
            messageParent("error", {messageError: "mrxs_is_null"});
        }
    }

    return (
        <div className="App">
            <input onChange={onFileChanged} type="file" style={{marginTop: "10px"}} ref={fileInput} id="type_file"
                   multiple="multiple" data-multiple-caption="{count} files selected"
                   className={"inputfile inputfile-1"}/>
            <label htmlFor="type_file" style={{marginTop: "10px"}}>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="17" viewBox="0 0 20 17">
                    <path
                        d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z"/>
                </svg>
                <span>Choose a file&hellip;</span>
            </label>

            <p style={{color: "red", marginTop: "65px", marginLeft: "5px", marginRight: "5px", fontSize: "12px"}}>If
                uploading in MIRAX format: <span style={{textDecoration: "underline"}}>After</span> selecting the .MRXS
                file above, please choose the associated folder with identical folder name below, and then click on the
                “Upload” button</p>
            <input onChange={onFolderChanged} type="file" id="type_folder" disabled="disabled" ref={folderInput}
                   directory="true" webkitdirectory="true" data-multiple-caption="{count} files selected"
                   className={"inputfolder inputfile-1"}/>
            <label htmlFor="type_folder" className={"label_type_folder"} id="label_type_folder">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="17" viewBox="0 0 20 17">
                    <path
                        d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z"/>
                </svg>
                <span>Choose a folder&hellip;</span>
            </label>
        </div>
    )
}

export default App
